<template>
  <a-drawer
    :visible="visible"
    title="导入经销商"
    placement="right"
    :width="1000"
    :mask-closable='false'
    @close="handleClose"
  >
    <div class="main">
      <h3 class="title" style="margin-bottom: 20px;">
        {{ activityDetail.activity_name }}
      </h3>

      <div class="way">
        <a-radio-group
          v-model="openType"
          @change="handleOpenTypeChange"
        >
          <a-radio :value="1">表格导入</a-radio>
          <a-radio :value="2">选择分组</a-radio>
        </a-radio-group>
      </div>

      <!-- 表格导入 -->
      <template v-if="openType === 1">
        <!-- 表单 -->
        <div class="block mt-35">
          <div class="block__content">
            <a-form-model
              class="form"
              ref="form"
              :model='form'
              :rules="rules"
              :label-col="{ span: 2 }"
              :wrapper-col="{ span: 22 }"
            >
              <a-form-model-item label="分组名称" prop="group_name">
                <a-input
                  v-model="form.group_name"
                  placeholder="请输入分组名称"
                  style="width: 250px;"
                />
              </a-form-model-item>
            </a-form-model>

            <a-upload-dragger
              name="file"
              accept=".XLSX, .XLS, .xlsx, .xls"
              :file-list="fileList"
              :custom-request="customRequest"
              @change="handleUploadChange"
            >
              <p class="ant-upload-drag-icon">
                <a-icon type="inbox" style="font-size: 60px;color:#00A0E9;"/>
              </p>
              <p class="ant-upload-text">
                点击或将文件拖拽到这里上传
              </p>
              <p class="ant-upload-hint">
                支持扩展名：.XLSX
              </p>
            </a-upload-dragger>
          </div>
        </div>

        <!-- 自定义表头 -->
        <div class="block mt-20">
          <div class="block__title">导入经销商模板表头</div>
          <div class="block__content">
            <template v-for="(tag, index) in headerList">
              <a-tooltip v-if="tag.length > 20" :key="tag" :title="tag">
                <a-tag :key="tag" :closable="index !== 0" @close="() => handleHeaderDel(tag)">
                  {{ `${tag.slice(0, 20)}...` }}
                </a-tag>
              </a-tooltip>
              <a-tag v-else :key="tag" :closable="index > 4" @close="() => handleHeaderDel(tag)">
                {{ tag }}
              </a-tag>
            </template>
            <a-tag v-if="!inputVisible" style="background: #fff; borderStyle: dashed" @click="showInput">
              <a-icon type="plus"/>
            </a-tag>
            <div v-else style="display: inline-block">
              <a-input
                ref="input"
                type="text"
                size="small"
                placeholder="请输入自定义表头"
                :style="{ width: '150px'}"
                :value="inputValue"
                @change="handleInputChange"
                @keyup.enter="handleInputConfirm"
              />
              <a-button type="link" @click="handleInputConfirm">确定</a-button>
              <a-button type="link" @click="handleInputCancel" style="margin-left: -20px">撤销</a-button>
            </div>
          </div>
        </div>

        <!-- 校验成功 -->
        <div class="block mt-20" v-if="fileStatus == 1">
          <div class="block__content">
            <div class="success_box">
              <div class="icon—box">
                <a-icon type="check-circle" theme="filled" style="font-size: 60px;color:#72cc07;"/>
                <div>
                  <p>校验成功</p>
                  <span>
                    请检查统计数据
                  </span>
                </div>
              </div>
              <div class="success_tabs">
                <a-table
                  :columns="uploadSuccessColumns"
                  :data-source="uploadSuccessDataList"
                  :rowKey="(record, index) => index"
                  :scroll="{ y: 300 }"
                  bordered
                  :pagination="false"
                >
                </a-table>
              </div>
            </div>
          </div>
        </div>

        <!-- 校验失败 -->
        <div class="block mt-20" v-if="fileStatus == 0">
          <div class="block__content">
            <div class="success_box err_box">
              <div class="icon—box">
                <a-icon type="close-circle" theme="filled" style="font-size: 60px;color:red;"/>
                <div>
                  <p>校验失败</p>
                  <span>请检查帐号异常数据或联系数据管理员。</span>
                  <a href="#" @click="downloadDealerError">下载帐号异常数据</a>
                </div>
              </div>
              <div class="success_tabs" v-show="uploadErrorDataList.length >0">
                <a-table
                  :scroll="{ y: 300 }"
                  bordered
                  :columns="uploadErrorColumns"
                  :data-source="uploadErrorDataList"
                  :pagination="false"
                  :rowKey="(record, index) => index"
                >
                  <div slot="dealerInfo" slot-scope="text, record">
                    <div>
                      <p>名称：{{ record.dealer_name }} </p>
                      <p>ID：{{ record.dealer_id }} </p>
                    </div>
                  </div>
                  <div slot="activityInfo" slot-scope="text, record">
                    <div>
                      <p>媒体帐号ID：{{ record.author_id }} </p>
                      <p>平台：{{ record.platform }}</p>
                    </div>
                  </div>
                  <div slot="errorMsg" slot-scope="text, record">
                    <p style="color:red;">{{ record.validate_message }}</p>
                  </div>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- 选择分组 -->
      <template v-if="openType === 2">
        <!-- 搜索表单 -->
        <div class="block mt-35">
          <div class="block__content">
            <a-form-model
              class="search-form"
              ref="searchForm"
              :model="searchForm"
              layout="inline"
            >
              <a-form-model-item label="分组名称" prop="group_name">
                <a-input
                  v-model="searchForm.group_name"
                  placeholder="请输入分组名称"
                  style="width: 250px;"
                />
              </a-form-model-item>
              <a-form-model-item>
                <a-button
                  class="mr-10"
                  type="primary"
                  icon="search"
                  @click="handleSearch"
                >
                  搜索
                </a-button>
                <a-button
                  icon="redo"
                  @click="handleReset"
                >
                  重置
                </a-button>
              </a-form-model-item>
            </a-form-model>
          </div>
        </div>

        <!-- 分组列表 -->
        <div class="block mt-20">
          <div class="block__title">分组列表</div>
          <div class="block__content">
            <a-table
              :loading="groupLoading"
              :columns="groupColumns"
              :data-source="groupList"
              :row-key="record => record.id"
              :row-selection="{
                selectedRowKeys: groupIds,
                onChange: handleGroupSelectChange
              }"
              :scroll="{ y: 300 }"
              :pagination="false"
              bordered
            >
              <div slot="status">
                <span>无告警</span>
              </div>
            </a-table>
            <!-- 分页配置 -->
            <base-pagination
              class="base-pagination"
              :currentPage="groupPagination.page_num"
              :pageSize="groupPagination.page_size"
              :total="groupPagination.total"
              :options="['10', '20', '50']"
              @onChange="handleGroupPaginationChange"
              @onShowSizeChange="handleGroupPaginationChange"
            />
          </div>
        </div>

        <!-- 明细列表 -->
        <div class="block mt-20">
          <div class="block__title">明细列表</div>
          <div class="block__content">
            <a-tabs v-model="activeKey">
              <a-tab-pane key="1" tab="已选择">
                <a-table
                  class="mb-20"
                  :loading="detailLoading"
                  :columns="detailColumns"
                  :data-source="detailList"
                  :row-key="record => record.id"
                  :row-selection="{
                    selectedRowKeys: detailSelectedIds,
                    onSelectAll: (selected, selectedRows, changeRows) => {
                      handleDetailSelectAll(1, selected, selectedRows, changeRows)
                    },
                    onSelect: (selectedRows, selected) => {
                      handleDetailSelect(1, selectedRows, selected)
                    },
                    onChange: (selectedRowKeys, selectedRows) => {
                      handleDetailSelectChange(1, selectedRowKeys, selectedRows)
                    }
                  }"
                  :scroll="{ y: 300 }"
                  :pagination="false"
                  bordered
                >
                  <div slot="dealerNumber" slot-scope="text, record">
                    {{ record.dealer_number || '-' }}
                  </div>
                  <div slot="platformType" slot-scope="text, record">
                    <span v-if="record.platform">
                      {{ record.platform }}
                    </span>
                    <span v-else>
                      {{ record.platform_type | platformTypeFilter }}
                    </span>
                  </div>
                </a-table>
              </a-tab-pane>
              <a-tab-pane key="2" tab="待选择">
                <a-table
                  class="mb-20"
                  :loading="detailLoading"
                  :columns="detailColumns"
                  :data-source="detail2List"
                  :row-key="record => record.id"
                  :row-selection="{
                    selectedRowKeys: detail2SelectedIds,
                    onChange: (selectedRowKeys, selectedRows) => {
                      handleDetailSelectChange(2, selectedRowKeys, selectedRows)
                    }
                  }"
                  :scroll="{ y: 300 }"
                  :pagination="false"
                  bordered
                >
                  <div slot="dealerNumber" slot-scope="text, record">
                    {{ record.dealer_number || '-' }}
                  </div>
                  <div slot="platformType" slot-scope="text, record">
                    <span>
                      {{ record.platform_type | platformTypeFilter }}
                    </span>
                  </div>
                </a-table>
              </a-tab-pane>
            </a-tabs>
          </div>
        </div>
      </template>
    </div>

    <div class="footer">
      <a
        class="mr-10"
        v-if="openType === 1"
        @click="exportExcelModel"
      >
        下载导入经销商模板
      </a>
      <a-button
        type="primary"
        :loading="saveLoading"
        @click="handleSave"
      >
        保存
      </a-button>
    </div>
  </a-drawer>
</template>

<script>
import trainingApi from '@/api/training_list'

export default {
  name: 'AddDealerDrawer',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    activityDetail: {
      type: Object,
      default () {
        return {
          activity_id: '',
          activity_name: ''
        }
      }
    }
  },
  data () {
    return {
      openType: 1,
      form: {
        group_name: ''
      },
      rules: {
        group_name: [
          { required: true, message: '请输入分组名称', trigger: 'blur' }
        ]
      },
      fileList: [],
      headerList: ['经销商ID', '经销商名称', '经销商编号', '媒体帐号ID', '平台'],
      inputVisible: false,
      inputValue: '',
      inputValueArr: [],
      fileStatus: -1,
      uploadSuccessColumns: [
        {
          dataIndex: 'dealer_count',
          title: '经销商数量'
        },
        {
          dataIndex: 'douyin_participate_count',
          title: '抖音帐号数'
        },
        {
          dataIndex: 'kuaishou_participate_count',
          title: '快手帐号数'
        },
        {
          dataIndex: 'dongchedi_participate_count',
          title: '懂车帝帐号数'
        }
      ],
      uploadSuccessDataList: [],
      keysSuccess: '',
      uploadErrorColumns: [
        {
          dataIndex: 'dealer_name',
          title: '经销商信息',
          scopedSlots: { customRender: 'dealerInfo' }
        },
        {
          dataIndex: 'author_id',
          title: '媒体帐号信息',
          scopedSlots: { customRender: 'activityInfo' }
        },
        {
          dataIndex: 'validate_message',
          title: '异常信息',
          scopedSlots: { customRender: 'errorMsg' }
        }
      ],
      uploadErrorDataList: [],
      codeError: '',
      keysError: '',
      searchForm: {
        group_name: ''
      },
      groupLoading: false,
      groupColumns: [
        {
          dataIndex: 'name',
          title: '分组名称'
        },
      ],
      groupList: [],
      groupPagination: {
        page_num: 1,
        page_size: 10,
        total: 0
      },
      groupIds: [],
      activeKey: '1',
      detailLoading: false,
      detailColumns: [
        {
          dataIndex: 'dealer_id',
          title: '经销商ID'
        },
        {
          dataIndex: 'dealer_name',
          title: '经销商名称'
        },
        {
          dataIndex: 'dealer_number',
          title: '经销商编号',
          scopedSlots: { customRender: 'dealerNumber' }
        },
        {
          dataIndex: 'author_id',
          title: '媒体帐号ID'
        },
        {
          dataIndex: 'platform_type',
          scopedSlots: { customRender: 'platformType' },
          title: '平台'
        },
      ],
      detailList: [],
      detailSelectedIds: [],
      detailSelectedList: [],
      detail2List: [],
      detail2SelectedIds: [],
      detail2SelectedList: [],
      saveLoading: false
    }
  },
  watch: {
    activityDetail: {
      immediate: true,
      handler (newVal, oldVal) {
        this.form.group_name = newVal.activity_name
      }
    }
  },
  methods: {
    // 处理方式改变
    handleOpenTypeChange () {
      if (this.openType === 2) {
        this.$refs.form.clearValidate()
        this.groupPagination.page_num = 1
        this.fetchGroupList()
      }
    },
    //
    async customRequest ({ file, onSuccess }) {
      try {
        const formData = new FormData()
        formData.append('activity_id', this.activityDetail.activity_id)
        formData.append('creator', this.$router.app.user.realname)
        formData.append('file', file)

        const { code, data, message } = await trainingApi.addDealer(formData)

        if (code == 200) {
          this.fileStatus = 1
          this.uploadSuccessDataList = [
            {
              dealer_count: data.dealer_count || 0,
              douyin_participate_count: data.douyin_participate_count || 0,
              kuaishou_participate_count: data.kuaishou_participate_count || 0,
              dongchedi_participate_count: data.dongchedi_participate_count || 0
            }
          ]
          this.keysSuccess = message
        } else {
          this.fileStatus = 0
          this.codeError = code
          this.keysError = message

          if (code == 116201) {
            this.$message.error(data || '经销商帐号超出数量限制')
          } else if (code == 116202) {
            this.$message.error('经销商帐号校验异常')
            this.uploadErrorDataList = data || []
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    // 上传文件
    handleUploadChange (info) {
      let fileList = [...info.fileList]

      fileList = fileList.slice(-1)
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.url
        }
        return file
      })

      this.fileList = fileList

      if (info.file.status === 'uploading') {
        info.file.status = 'success'
      }

      if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 文件上传失败`)
      }
    },
    showInput () {
      this.inputVisible = true
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    }, // 显示输入框
    handleInputChange (e) {
      this.inputValue = e.target.value
    },
    //
    handleInputConfirm () {
      const inputValue = this.inputValue
      let headerList = this.headerList

      if (inputValue && headerList.indexOf(inputValue) === -1) {
        headerList = [...headerList, inputValue]
        this.inputValueArr.push(inputValue)
        Object.assign(this, {
          headerList,
          inputVisible: false,
          inputValue: ''
        })
      } else {
        this.$message.error('请输入表头名称')
      }
    },
    //
    handleInputCancel () {
      this.inputVisible = false
      this.inputValue = ''
    },
    // 处理表头删除
    handleHeaderDel (removedTag) {
      const tags = this.headerList.filter(tag => tag !== removedTag)
      const tags1 = this.inputValueArr.filter(tag => tag !== removedTag)

      this.headerList = tags
      this.inputValueArr = tags1
    }, // 删除表头
    // 下载导入经销商模板
    async exportExcelModel () {
      try {
        const extra_title = this.inputValueArr.length ? this.inputValueArr.join(',') : ''
        const res = await trainingApi.exportExcelModel(extra_title)
        const fileName = '经销商媒体帐号导入模板'

        /* 兼容ie内核，360浏览器的兼容模式 */
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          const blob = new Blob([res])
          window.navigator.msSaveOrOpenBlob(blob, fileName + '.xlsx')
        } else {
          /* 火狐谷歌的文件下载方式 */
          let blob = new Blob([res])
          let downloadElement = document.createElement('a')
          let href = window.URL.createObjectURL(blob)
          downloadElement.href = href
          downloadElement.download = fileName + '.xlsx'
          document.body.appendChild(downloadElement)
          downloadElement.click()
          document.body.removeChild(downloadElement)
          window.URL.revokeObjectURL(href)
        }
      } catch (e) {
        console.log(e)
      }
    },
    // 下载异常数据
    async downloadDealerError () {
      try {
        const params = {
          code: this.codeError,
          key: this.keysError
        }
        const fileName = '经销商异常数据' + new Date().getTime()
        const res = await trainingApi.downloadDealerError(params)

        /* 兼容ie内核，360浏览器的兼容模式 */
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          const blob = new Blob([res])
          window.navigator.msSaveOrOpenBlob(blob, fileName + '.xlsx')
        } else {
          /* 火狐谷歌的文件下载方式 */
          let blob = new Blob([res])
          let downloadElement = document.createElement('a')
          let href = window.URL.createObjectURL(blob)
          downloadElement.href = href
          downloadElement.download = fileName + '.xlsx'
          document.body.appendChild(downloadElement)
          downloadElement.click()
          document.body.removeChild(downloadElement)
          window.URL.revokeObjectURL(href)
        }
      } catch (e) {
        console.log(e)
      }
    },
    // 处理分组搜索
    handleSearch () {
      this.groupPagination.page_num = 1
      this.fetchGroupList()
    },
    // 处理分组重置
    handleReset () {
      this.$refs.searchForm.resetFields()
      this.groupPagination.page_num = 1
      this.fetchGroupList()
    },
    // 处理分组分页改变
    handleGroupPaginationChange (page_num, page_size) {
      this.groupPagination.page_num = page_num
      this.groupPagination.page_size = page_size
      this.fetchGroupList()
    },
    // 获取分组列表
    async fetchGroupList () {
      this.groupLoading = true
      try {
        const { page_num, page_size } = this.groupPagination
        const params = {
          type: 1,
          name: this.searchForm.group_name,
          page_num,
          page_size
        }
        const { code, data, message } = await trainingApi.getGroupNameList(params)

        if (code == 200) {
          this.groupList = data.list || []
          this.groupPagination.total = data.total || 0
          this.groupLoading = false
        } else {
          this.$message.error(message)
          this.groupLoading = false
        }
      } catch (e) {
        console.log(e)
        this.groupLoading = false
      }
    },
    // 处理分组选择改变
    handleGroupSelectChange (selectedRowKeys, selectedRows) {
      this.groupIds = selectedRowKeys
      this.fetchDetailList()
    },
    // 获取明细列表
    async fetchDetailList () {
      this.detailLoading = true
      try {
        const params = {
          activity_id: this.activityDetail.activity_id,
          group_ids: this.groupIds || []
        }
        const { code, data, message } = await trainingApi.getDetailList(params)

        if (code == 200) {
          this.detailList = data.activity_data || []

          const arr = Object.values(data.group_data || {})
          const newArr = [].concat.apply([], arr)
          this.detail2List = newArr || []

          this.detailLoading = false
        } else {
          this.$message.error(message)
          this.detailLoading = false
        }
      } catch (e) {
        console.log(e)
        this.datailLoading = false
      }
    },
    //
    handleDetailSelectAll (type, selected, selectedRows, changeRows) {
      if (type === 1) {
        this.detail2List = [...this.detail2List, ...changeRows]
        let arr = changeRows.map(item => item.id)
        // 过滤
        const list = this.detailList.filter(tag => !arr.includes(tag.id))
        const newArr = [].concat.apply([], list)
        this.detailList = newArr
      }
    },
    //
    handleDetailSelect (type, selectedRows, selected) {
      if (type === 1) {
        let newList = []
        newList.push(selectedRows)

        this.detail2List = [...this.detail2List, ...newList]

        let arr = newList.map(item => item.id)
        // 过滤
        const list = this.detailList.filter(tag => !arr.includes(tag.id))
        const newArr = [].concat.apply([], list)
        this.detailList = newArr
      }
    },
    //
    handleDetailSelectChange (type, selectedRowKeys, selectedRows) {
      if (type === 1) {
        this.detailSelectedIds = selectedRowKeys
      }

      if (type === 2) {
        if (selectedRowKeys.length > 0) {
          this.detailList = [...this.detailList, ...selectedRows]
          // 过滤
          const list = this.detail2List.filter(tag => !selectedRowKeys.includes(tag.id))
          const newArr = [].concat.apply([], list)
          this.detail2List = newArr
          this.detailSelectedIds = this.detailList.map(item => item.id)
          this.detailSelectedList = this.detailList
        }
      }
    },
    // 处理保存
    handleSave () {
      // 表格导入
      if (this.openType === 1) {
        this.$refs.form.validate(valid => {
          if (valid) {
            if (!this.keysSuccess) {
              this.$message.error('key不能为空')
              return false
            }

            this.saveExcel()
          } else {
            this.$message.error('分组名称不能为空')
            return false
          }
        })
      }

      // 选择分组
      if (this.openType === 2) {
        if (this.detailSelectedList.length === 0) {
          this.$message.error('请选择经销商明细')
          return false
        }

        this.detailSelectedList.map(item => {
          delete item.is_deleted
        })

        this.saveDealer()
      }
    },
    //
    async saveExcel () {
      this.saveLoading = true
      try {
        const params = {
          activity_id: this.activityDetail.activity_id,
          group_name: this.form.group_name,
          key: this.keysSuccess,
          creator: this.$router.app.user.realname
        }

        const { code, message } = await trainingApi.saveExcel(params)

        if (code == 200) {
          this.$message.success(message || '保存成功')
          this.saveLoading = false
          this.$emit('update:visible', false)
          this.resetData()
          this.$emit('save')
        } else {
          this.$message.error(message || '保存失败')
          this.saveLoading = false
        }
      } catch (e) {
        console.log(e)
        this.saveLoading = false
      }
    },
    //
    async saveDealer () {
      this.saveLoading = true
      try {
        const params = {
          activity_id: this.activityDetail.activity_id,
          dealer_list: this.detailSelectedList
        }
        const { code } = await trainingApi.saveDealer(params)

        if (code == 200) {
          this.$message.success('保存成功')
          this.saveLoading = false
          this.$emit('update:visible', false)
          this.resetData()
          this.$emit('save')
        } else {
          this.$message.error('保存失败')
          this.saveLoading = false
        }
      } catch (e) {
        console.log(e)
        this.saveLoading = false
      }
    },
    // 处理关闭
    handleClose () {
      this.$emit('update:visible', false)
      this.resetData()
    },
    //
    resetData () {
      this.form.group_name = ''
      this.fileList = []
      this.headerList = ['经销商ID', '经销商名称', '经销商编号', '媒体帐号ID', '平台']
      this.inputValue = ''
      this.fileStatus = -1
      this.uploadSuccessDataList = []
      this.keysSuccess = ''
      this.uploadErrorDataList = []
      this.codeError = ''
      this.keysError = ''

      this.searchForm.group_name = ''
      this.groupList = []
      this.groupIds = []
      this.activeKey = '1'
      this.detailList = []
      this.detailSelectedIds = []
      this.detailSelectedList = []
      this.detail2List = []
      this.detail2SelectedIds = []
      this.detail2SelectedList = []

      this.openType = 1
    }
  },
  filters: {
    platformTypeFilter (val) {
      let text = '-'

      val === 1 && (text = '抖音')
      val === 2 && (text = '快手')
      val === 3 && (text = '懂车帝')

      return text
    }
  }
}
</script>

<style lang="less" scoped>
.mt-35 {
  margin-top: 35px;
}

.mt-20 {
  margin-top: 20px;
}

.mr-10 {
  margin-right: 10px;
}

/deep/ .ant-drawer-wrapper-body {
  overflow: hidden;
}

.main {
  margin: -24px;
  padding: 24px 24px 73px;
  height: calc(100vh - 55px);
  overflow: auto;
}

.block {
  &__title {
    padding: 15px 0;
    color: #333;
    font-size: 16px;
    font-weight: bold;
  }
}

/deep/ .ant-tag {
  margin-right: 0px;
  margin-left: -1px;
  padding: 5px 18px;
  font-size: 14px;
  background: transparent;
}
/deep/ .ant-tag:nth-last-child(2) {
  margin-right: 30px;
}
/deep/ .ant-input-sm {
  height: 32px;
}

.success_box {
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid #ccc;

  .icon—box {
    display: flex;
    align-items: center;

    div {
      padding-left: 20px;

      p {
        font-size: 26px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 0px;
      }

      span {
        font-size: 14px;
        color: #9d9c9c;
      }
    }
  }

  .success_tabs {
    margin-top: 20px;
  }
}

.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  padding: 10px 16px;
  width: 100%;
  text-align: right;
  background: #fff;
  border-top: 1px solid #e9e9e9;
}
</style>
