<template>
  <a-card class="page-container">
    <a-form-model
      class="search-form"
      ref='search_form'
      :model='search_form'
      :label-col="{span: 5}"
      :wrapper-col="{span: 19}"
    >
      <a-row :gutter="60">
        <a-col :span="8">
          <a-form-model-item
            label='名称'
          >
            <a-input
              placeholder='请输入名称'
              v-model='search_form.activity_name'
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item
            label='品牌（主体）'
          >
            <a-select
              v-model='search_form.principal_id'
              placeholder='请选择品牌（主体）'
              show-search
              option-filter-prop='children'
            >
              <a-select-option
                v-for='item in principal_id_list'
                :key='item.id'
              >
                {{ item.principal_name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item
            label='状态'
          >
            <a-select
              v-model='search_form.status'
              placeholder='请选择状态'
            >
              <a-select-option
                v-for='item in state_list'
                :key='item.id'
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item
            label='活动起始时间'
          >
            <a-range-picker
              :placeholder="['开始时间', '结束时间']"
              show-time
              format='YYYY-MM-DD HH:mm:ss'
              inputReadOnly
              @change="onChangeDate(arguments, 'search')"
              style='width:100%'
              v-model='search_form.time'
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item :wrapper-col="{span: 19, offset: 5}">
            <a-space>
              <a-button type="primary" @click='bindSearch'>
                搜索
              </a-button>
              <a-button @click='resetSearch'>
                重置
              </a-button>
            </a-space>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <div class="oper-bar">
      <a-button
        type='danger'
        icon="plus"
        @click='handleOpen(0)'
      >
        添加活动
      </a-button>
    </div>

    <div class="data-table">
      <a-table
        :columns='columns'
        :data-source='data_list'
        :pagination='false'
        :rowKey='(record, index) => index'
        :loading='loading'
      >
        <div slot='activity_info' slot-scope='text, record'>
          <div class='row_box'>
            <span>名称：</span>{{ record.activity_name || '-' }}
          </div>
          <div class='row_box'>
            <span>主体：</span>{{ record.principal_name || '-' }}
          </div>
          <div class='row_box'>
            <span>状态：</span>
            <a-tag color='green' v-if='record.status === 0'>未开始</a-tag>
            <a-tag color='green' v-else-if='record.status === 1'>进行中</a-tag>
            <a-tag color='green' v-else>已结束</a-tag>
          </div>
        </div>
        <div slot='dealer_count' slot-scope='text, record'>
          <div class='row_box'>
            <span>经销商数量：</span>{{ record.dealer_count || '0' }}
          </div>
          <div class='row_box'>
            <span>绑定微信：</span>{{ record.bind_weixin_count || '0' }}
          </div>
          <div class='row_box'>
            <span>媒体帐号数：</span>{{ record.author_count || '0' }}
          </div>
          <div class='row_box'>
            <span>创作者授权异常：</span>{{ record.p1_exception_count || '0' }}
          </div>
          <div class='row_box'>
            <span>互动数据授权异常：</span>{{ record.p2_exception_count || '0' }}
          </div>
        </div>
        <div slot='finish_time' slot-scope='text, record'>
          <div class='time-box'>
            <div>起始时间：</div>
            <div>{{ record.publish_time }}</div>
          </div>
          <div class='time-box'>
            <div>结束时间：</div>
            <div>{{ record.finish_time }}</div>
          </div>
        </div>
        <div slot='operation' slot-scope='text, record, index'>
          <a class='del' @click='handleDelete(record.id)'>删除</a>

          <a-divider type='vertical' />
          <a @click='handleOpen(1, record)'>编辑</a>

          <a-divider type='vertical' />
          <a @click='jumpDealerPage(record)'>经销商媒体帐号列表</a>

          <a-divider v-if="record.account_group_id" type='vertical' />
          <a
            v-if="record.account_group_id"
            :id="`check_${index}`"
            :data-clipboard-text="getCheckUrl(record)"
            @click="handleCopy(index)"
          >
            在线查看名单
          </a>
        </div>
      </a-table>
      <!-- 分页配置 -->
      <base-pagination
        :currentPage='current'
        :pageSize='page_size'
        :total='total'
        @onChange='onChange'
        @onShowSizeChange='onChange'
      />
    </div>

    <!-- 添加/编辑活动 -->
    <a-drawer
      :title="is_training_add ? '添加活动' : '编辑活动'"
      placement='right'
      :width='600'
      :maskClosable='false'
      :visible='visible'
      @close='handleCancel'
    >
      <h3 class='title' style='margin-bottom: 20px;'>活动详情</h3>
      <a-form-model
        ref='data_form'
        :model='data_form'
        :rules='rules'
        v-bind='data_layout'
      >
        <a-form-model-item
          label='名称'
          prop='activity_name'
        >
          <a-input
            placeholder='请输入名称'
            v-model='data_form.activity_name'
          />
        </a-form-model-item>
        <a-form-model-item
          label='品牌（主体）'
          prop='principal_id'
        >
          <a-select
            v-model='data_form.principal_id'
            placeholder='请选择品牌（主体）'
            show-search
            option-filter-prop='children'
            style='width: 100%'
            :disabled='!is_training_add'
            @change='onChangePrincipal'
          >
            <a-select-option
              v-for='item in principal_id_list'
              :key='item.id'
              :name='item.principal_name'
            >
              {{ item.principal_name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          label='活动时间'
          prop='time'
        >
          <a-range-picker
            :placeholder="['开始时间', '结束时间']"
            show-time
            format='YYYY-MM-DD HH:mm:ss'
            inputReadOnly
            :show-time="{
              defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')]
            }"
            @change="onChangeDate(arguments, 'form')"
            style='width:100%'
            v-model='data_form.time'
            :disabled-date='disabledStartDate'
          />
        </a-form-model-item>
        <a-form-model-item
          label='经销商数量'
          v-if='!is_training_add'
        >
          {{ data_form.dealer_count }}
        </a-form-model-item>
        <a-form-model-item
          :wrapper-col="{
            offset: 5,
            span: 19
          }"
        >
          <a-button
            type="primary"
            :loading='saveLoading'
            @click='handleSubmit'
          >
            保存
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-drawer>

    <!-- 导入经销商 -->
    <add-dealer-drawer
      :visible.sync="addDealerVisible"
      :activity-detail="addActivityDetail"
      @save="handleDealerSave"
    />
  </a-card>
</template>

<script>
import moment from 'moment'
import Clipboard from 'clipboard'
import AddDealerDrawer from './components/AddDealerDrawer'
import _https from '@/api/training_list'

export default {
  components: {
    AddDealerDrawer
  },
  data () {
    return {
      value: null,
      search_form: {},
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 }
      },
      principal_id_list: [],
      state_list: [
        { id: '', name: '全部' },
        { id: 0, name: '未开始' },
        { id: 1, name: '进行中' },
        { id: 2, name: '已结束' }
      ],
      columns: [
        {
          dataIndex: 'activity_name',
          scopedSlots: { customRender: 'activity_info' },
          width: 200,
          title: '活动信息'
        },
        // {
        //   dataIndex: 'principal_name',
        //   width: 150,
        //   title: '品牌(主体)'
        // },
        // {
        //   dataIndex: 'status',
        //   scopedSlots: { customRender: 'status' },
        //   width: 150,
        //   title: '状态'
        // },
        {
          dataIndex: 'dealer_count',
          scopedSlots: { customRender: 'dealer_count' },
          width: 150,
          title: '经销商数据'
        },
        {
          dataIndex: 'finish_time',
          scopedSlots: { customRender: 'finish_time' },
          width: 150,
          title: '时间'
        },
        {
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: 230,
          title: '操作'
        }
      ],
      data_list: [],
      loading: false,
      total: 0,
      current: 1,
      page_size: 10,
      visible: false,
      is_training_add: true,
      data_form: {
        douyin_participate_count: 0,
        kuaishou_participate_count: 0,
        dongchedi_participate_count: 0,
        id: undefined,
        activity_name: '',
        principal_id: undefined,
        principal_name: '',
        finish_time: '',
        publish_time: '',
        time: null,
        dealer_count: 0,
        group_list: [],
        creator: null,
        modifier: null
      },
      data_layout: {
        labelCol: { span: 5 },
        wrapperCol: { span: 19 }
      },
      rules: {
        activity_name: [
          { required: true, message: '名称', trigger: 'change' }
        ],
        principal_id: [
          { required: true, message: '请选择品牌（主体）', trigger: 'change' }
        ],
        time: [
          { required: true, message: '活动时间', trigger: 'change' }
        ]
      },
      is_upload: false,
      input_visible: false,
      input_value: '',
      file_list: [],
      custom_request_type: 'excel',
      saveLoading: false,
      addActivityDetail: {}, // 导入经销商时，需要用到
      addDealerVisible: false
    }
  },
  mounted () {
    this.getPrincipals()
    this.getList()
  },
  methods: {
    moment,
    //获取主体
    getPrincipals () {
      _https.getPrincipals().then(res => {
        if (res.code == 200) {
          this.principal_id_list = res.data
        } else {
          this.principal_id_list = []
          console.log('获取主体失败')
        }
      })
    },
    //搜索
    bindSearch () {
      this.current = 1
      this.getList()
    },
    //重置
    resetSearch () {
      this.search_form = {}
      this.current = 1
      this.page_size = 10
      this.getList()
    },
    //翻页
    onChange (current, page_size) {
      this.current = current
      this.page_size = page_size
      this.getList()
    },
    //日期选择框
    onChangeDate (data, type) {
      let publish_time = ''
      let finish_time = ''

      console.log(data)

      if (data[0] && data[0].length > 0) {
        let dates = data[0]
        publish_time = this.$moment(dates[0]._d).format('YYYY-MM-DD HH:mm:ss')
        finish_time = this.$moment(dates[1]._d).format('YYYY-MM-DD HH:mm:ss')
      }

      if (type === 'search') {
        this.search_form.finish_time = finish_time
        this.search_form.publish_time = publish_time
      } else {
        this.data_form.finish_time = finish_time
        this.data_form.publish_time = publish_time
      }
    },
    disabledStartDate (current) {
      return current && current < moment().startOf('day')
    },
    disabledDateTime () {
      let time = moment().format('HH:mm:ss')
      let time_arr = time.split(':')
      return {
        disabledHours: () => this.range(0, 24).splice(0, time_arr[0]),
        disabledMinutes: () => this.range(0, time_arr[1])
      }
    },
    range (start, end) {
      const result = []
      for (let i = start; i < end; i++) {
        result.push(i)
      }
      return result
    },
    //检索列表
    getList () {
      this.loading = true
      let params = {
        activity_name: this.search_form.activity_name,
        principal_id: this.search_form.principal_id,
        status: this.search_form.status,
        publish_time: this.search_form.publish_time,
        finish_time: this.search_form.finish_time,
        current: this.current,
        page_size: this.page_size
      }
      _https.getList(params).then(res => {
        this.loading = false
        if (res.code == 200) {
          this.data_list = res.data.list || []
          this.total = res.data.pagination.total || 0
        } else {
          this.data_list = []
          this.total = 0
          console.log('获取活动列表失败')
        }
      })
    },
    //跳转经销商列表
    jumpDealerPage (record) {
      this.$router.push({
        name: 'actManage:dealer',
        query: {
          activity_id: record.id,
          activity_name: record.activity_name,
          dealer_count: record.dealer_count,
          principal_id: record.principal_id,
          author_count: record.author_count,
          p1_exception_count: record.p1_exception_count,
          p2_exception_count: record.p2_exception_count
        }
      })
    },
    //删除
    handleDelete (id) {
      let that = this
      let params = {
        id: id,
        data: {
          modifier: this.$router.app.user.realname
        }
      }
      this.$confirm({
        title: `删除该活动，后台将不再显示该活动`,
        okText: '确定',
        cancelText: '取消',
        onOk () {
          new Promise((resolve, reject) => {
            resolve(_https.deteleTraining(params))
          })
            .then(res => {
              if (res.code == 200) {
                that.$message.success('删除成功')
                that.getList()
              } else {
                that.$message.error('删除失败')
              }
            })
        }
      })
    },
    //打开详情
    handleOpen (type, record) {
      this.visible = true
      if (type) {
        this.is_training_add = false
        this.$set(this.data_form, 'douyin_participate_count', record.douyin_participate_count)
        this.$set(this.data_form, 'kuaishou_participate_count', record.kuaishou_participate_count)
        this.$set(this.data_form, 'dongchedi_participate_count', record.dongchedi_participate_count)
        this.data_form.activity_name = record.activity_name
        this.data_form.principal_id = record.principal_id
        this.data_form.principal_name = record.principal_name
        this.data_form.id = record.id
        this.data_form.publish_time = record.publish_time
        this.data_form.finish_time = record.finish_time
        this.data_form.time = [moment(record.publish_time, 'YYYY-MM-DD HH:mm:ss'), moment(record.finish_time, 'YYYY-MM-DD HH:mm:ss')]
        this.data_form.dealer_count = record.dealer_count
        this.data_form.group_list = record.group_list
      } else {
        this.checkboxValue = ['1']
        this.is_training_add = true
      }
    },
    //关闭详情
    handleCancel () {
      this.visible = false
      this.data_form = {
        id: undefined,
        activity_name: '',
        principal_id: undefined,
        principal_name: '',
        finish_time: '',
        publish_time: '',
        time: null,
        dealer_count: 0,
        group_list: [],
        creator: null,
        modifier: null
      }
      this.is_upload = false
      this.input_value = ''
      this.input_visible = false
      this.$refs.data_form.clearValidate()
    },
    onChangePrincipal (value, option) {
      this.data_form.principal_name = option.data.attrs.name
    },
    handleSubmit () {
      this.$refs.data_form.validate((valid, obj) => {
        if (valid) {
          this.saveLoading = true
          let params = {
            method: this.is_training_add ? 'post' : 'put',
            data: {
              id: this.is_training_add ? undefined : this.data_form.id,
              principal_id: this.data_form.principal_id,
              principal_name: this.data_form.principal_name,
              activity_name: this.data_form.activity_name,
              publish_time: this.data_form.publish_time,
              finish_time: this.data_form.finish_time,
              creator: this.is_training_add ? this.$router.app.user.realname : undefined,
              modifier: this.is_training_add ? undefined : this.$router.app.user.realname,
              dealer_count: this.data_form.dealer_count || 0,
              group_list: this.data_form.group_list || [],
              douyin_participate_count: this.data_form.douyin_participate_count || 0,
              kuaishou_participate_count: this.data_form.kuaishou_participate_count || 0,
              dongchedi_participate_count: this.data_form.dongchedi_participate_count || 0
            }
          }
          _https.editTraining(params).then(res => {
            if (res.code == 200) {
              this.$message.success('提交成功')
              this.saveLoading = false
              this.addActivityDetail = {
                activity_id: res.data,
                activity_name: this.data_form.activity_name
              }
              this.handleCancel()
              this.getList()

              if (this.is_training_add) {
                this.addDealerVisible = true
              }
            } else if (res.code == 500) {
              this.$message.error('提交失败，已存在相同活动名称')
              this.saveLoading = false
            } else {
              this.$message.error('提交失败')
              this.saveLoading = false
            }
          })
        }
      })
    },
    handleClose (removedTag) {
      console.log(this.data_form.group_list)
      const group_list = this.data_form.group_list.filter(tag => tag.group_name !== removedTag)
      this.$set(this.data_form, 'group_list', group_list)
    },
    showInput () {
      this.input_visible = true
      this.$nextTick(function () {
        this.$refs.input.focus()
      })
    },
    handleInputChange (e) {
      this.input_value = e.target.value
    },
    handleInputConfirm () {
      const input_value = this.input_value.trim()
      if (input_value) {
        let group_list = this.data_form.group_list
        let arr = group_list.filter(tag => tag.group_name === input_value)

        if (arr.length < 1) {
          group_list = [...group_list, { 'id': '', 'group_name': input_value }]
        }
        this.data_form.group_list = group_list
        this.input_visible = false
        this.input_value = ''
      } else {
        this.input_value = ''
        this.input_visible = false
      }
    },
    async customRequest ({ file, onSuccess }) {
      const formData = new FormData()
      formData.append('activity_id', this.data_form.id)
      formData.append('creator', this.$router.app.user.realname)
      formData.append('file', file)

      _https.addDealer(formData).then(res => {
        if (res.code == 200) {
          this.is_upload = false
          this.data_form.dealer_count = res.data.dealer_count
        } else {
          this.is_upload = false
          this.$message.error('重复导入或者导入excel为空')
        }
      })
    },
    beforeUpload (file) {
      const arr = file.name.split('.')
      const type = arr[arr.length - 1].toUpperCase()
      const isExcel = ['XLSX', 'XLS'].includes(type)

      if (!isExcel) {
        this.$message.error('请上传Excel文件')
      }

      return isExcel
    },
    handleUploadChange (info) {
      console.log('handleUploadChange', info)
      this.is_upload = true
      this.file_list = info.fileList

      if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 文件上传失败`)
      }
    },
    // 处理经销商保存
    handleDealerSave () {
      this.addActivityDetail = {}
      this.getList()
    },
    // 获取查看名单地址
    getCheckUrl (record) {
      const H5_HOST = process.env.VUE_APP_H5_HOST

      return `${H5_HOST}/groupAuthorList?group_id=${record.account_group_id}`
    },
    // 处理查看名单地址复制
    handleCopy (index) {
      const clipboard = new Clipboard(`#check_${index}`)

      clipboard.on('success', (e) => {
        this.$message.success('已复制链接')
        clipboard.destroy() // 释放内存
      })

      clipboard.on('error', (e) => {
        this.$message.error('该浏览器不支持自动复制')
        clipboard.destroy() // 释放内存
      })
    }
  }
}
</script>

<style lang='less' scoped>
.page-container {
  padding: 0 !important;
}

.search-form .ant-form-item {
  margin-bottom: 10px;
}

.oper-bar {
  margin: 10px 0 0;
  text-align: right;
}

.data-table {
  margin: 20px 0 0;
}

.row_box {
  padding: 2px 0;
}

.mt-40p {
  margin-top: 40px;
}

.mr-10 {
  margin-right: 10px;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.time-box {
  margin-bottom: 5px;
  text-align: left;

  div:first-child {
    font-weight: bold;
  }

  div:last-child {
    white-space: nowrap;
  }
}

.del {
  color: red;
}

.excel-btn {
  position: relative;
  overflow: hidden;

  .upload {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }
}

.checkbox-group /deep/ .ant-row {
  margin-bottom: 24px;
}

.inputNumber {
  margin-left: 16px;
}
</style>
